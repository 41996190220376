.Tech {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background: #f9f9f9;
  &__title {
    width: 100%;
    background-size: cover;
    background-position: center;
    padding: 20px;
    h2 {
      font-size: 1.4em;
      width: 70%;
      margin-bottom: 10px;
    }
    p {
      font-size: 1em;
      font-weight: 400;
      width: 80%;
      line-height: 150%;
      span {
        font-weight: 700;
      }
    }
  }
  &__steps {
    padding: 20px 20px 20px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    &__step {
      display: flex;
      gap: 10px;
      span {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 60px;
        height: 30px;
        background: #29347c;
        padding: 10px;
        color: #fff;
        font-family: Oswald;
        font-size: 1.6em;
        font-style: normal;
        font-weight: 600;
      }
      &__description {
        width: 100%;
        p {
          font-size: 1em;
          font-weight: 400;
          line-height: 150%;
        }
        h4 {
          font-size: 1em;
          font-weight: 700;
          margin-bottom: 4px;
        }
      }
    }
  }
  &__points {
    display: flex;
    gap: 20px;
    padding: 20px;
    ul {
      padding-left: 20px;
      li {
        list-style-type: none; /* Убирает стандартные маркеры */
        position: relative;
        width: 100%;
        font-size: 0.85em;
        font-weight: 600;
        line-height: 140%;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 30px;
        word-wrap: break-word;
      }
      li:last-child {
        margin-bottom: 0;
      }
      li::before {
        content: "";
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg); /* Добавлен поворот для создания ромба */
        width: 10px; /* Ширина ромба */
        height: 10px; /* Высота ромба */
        background-color: #29337c; /* Цвет ромба */
      }
    }
  }
}
