.Button {
  background-color: #f9c71b;
  width: fit-content;
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #29347c;
  font-size: 1em;
  cursor: pointer;
}
