.Use {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 30px 20px;
  h3 {
    font-size: 1.4em;
    line-height: 140%; /* 26.6px */
  }
  ul {
    li {
      width: 100%;
      font-size: 0.85em;
      font-weight: 600;
      line-height: 140%;
      display: flex;
      align-items: start;
      gap: 10px;
      margin-top: 20px;
      word-wrap: break-word;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__where {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }
  &__what {
    display: flex;
    flex-direction: column;

    &__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      padding-left: 15px;
      .customList li {
        list-style-type: none; /* Убирает стандартные маркеры */
        position: relative;
      }
      li::before {
        content: "";
        position: absolute;
        left: -15px;
        top: 6px;
        transform: translateY(-50%) rotate(45deg); /* Добавлен поворот для создания ромба */
        width: 6px; /* Ширина ромба */
        height: 6px; /* Высота ромба */
        background-color: #29337c; /* Цвет ромба */
      }
    }
  }
}
