.Header {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  &__logo {
    width: auto;
    img {
      width: 160px;
      object-fit: contain;
    }
  }
  &__contact {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-items: end;
    align-items: end;
    a {
      color: #29337c;
      font-size: 1.4em;
    }
  }
}
