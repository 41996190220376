.Products {
  display: block;
  background: #f9f9f9;
  padding: 20px;
  h2 {
    font-size: 1.4em;
    line-height: 140%;
    margin-bottom: 20px;
  }
  .Products__carousel {
    &__slide {
      background: #fff;
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
      &__property {
        display: flex;
        flex-direction: column;
        h4 {
          color: #000;
          font-size: 1em;
          line-height: 140%;
          display: flex;
          padding: 4px 10px;
          border-top: 1px solid #29337c;
          border-left: 1px solid #29337c;
          border-right: 1px solid #29337c;
          width: fit-content;
          align-self: end;
        }
        p {
          font-size: 1em;
          font-weight: 400;
          line-height: 150%; /* 19.6px */
          border-top: 1px solid #29337c;
          border-bottom: 1px solid #29337c;
          padding: 10px 0;
        }
      }
    }
    &__arrows {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      padding: 20px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
