@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap");

/* Reset styles */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

/* Set default font family and size */
body * {
  font-family: "Inter", sans-serif;
  color: #1e1e1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 130%;
  /* 15.6px */
}

/* Remove list styles */
ul,
ol {
  list-style: none;
}

/* Remove default link styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Remove default button styles */
button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

/* Add box-sizing border-box to all elements */
* {
  box-sizing: border-box;
}