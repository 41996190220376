.Contact {
  background: #29347c;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  input,
  textarea {
    font-size: 1em;
    line-height: 150%;
    font-weight: 400;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 0 !important;
  }
}
