.About {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  img {
    width: 100%;
    object-fit: fill;
  }
  h3 {
    font-size: 1.4em !important;
    font-weight: 700 !important;
    color: #fff;
  }
  &__block {
    background: #29347c;
    padding: 20px;
    * {
      color: #fff !important;
      line-height: 140%; /* 26.6px */
    }
    p {
      font-size: 1em !important;
      font-weight: 400 !important;
      line-height: 150%;
    }
  }
  &__columns {
    display: grid; // Add this line
    grid-template-columns: 1fr 1fr; // Add this line
    padding: 0px 20px 30px 20px;
    column-gap: 20px;

    li {
      font-size: 1em;
      font-weight: 400;
      margin-top: 30px;
      width: 100%;
      line-height: 150%;
      h4 {
        font-size: 1em;
        font-weight: 700;
        margin-bottom: 4px;
      }
    }
    li:last-child {
      margin-bottom: 0;
    }
  }
}
