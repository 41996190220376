.Eco {
  width: 100%;
  height: 100vw;
  margin-top: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: end;
  &__block {
    background: #29347c;
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      color: #29347c;
      font-size: 2.8em;
      text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
        1px 1px 0 #fff;
    }
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end !important;
      row-gap: 10px;
      h3 {
        font-size: 1.4em !important;
        font-weight: 700 !important;
        color: #fff;
      }
    }
  }
}
