.Hero {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    color: #29347c;
    padding: 20px;
    font-size: 1.4em;
    line-height: 140%;
  }
  img {
    height: 270px;
    width: auto;
    object-fit: cover;
  }
  &__block {
    background: #29337c;
    padding: 30px;
    display: grid;
    height: auto;
    grid-template: repeat(3, auto) / 20% 10% 1fr;
    &__part1 {
      grid-column: 1/2;
      grid-row: 1/2;
    }
    &__part2 {
      grid-column: 2/4;
      grid-row: 1/3;
    }
    &__part3 {
      grid-column: 1/4;
      grid-row: 3/4;
      justify-self: end;
    }
    span {
      color: #fff;
      font-size: 1.4em;
      font-weight: 700;
      line-height: 140%; /* 28px */
    }
    h1 {
      color: #29347c;
      font-family: Oswald;
      text-align: right;
      font-size: 10em;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -3.4px;
      text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff,
        2px 2px 0 #fff;
      padding: 10px;
    }
  }
}
